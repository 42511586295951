import { mapActions, mapGetters } from 'vuex';
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { cloneDeep } from 'lodash';
import { firstnameValidation, lastnameValidation } from '@/common/validationHelpers';
import { EMAIL, FIRST_NAME, LAST_NAME } from '@/common/constants/validations';
import { MESSAGES } from '@/common/constants/errorMessages';

export default {
  validations: {
    editedItem: {
      last_name: { required, lastnameValidation, maxLength: maxLength(LAST_NAME.MAX_LENGTH) },
      first_name: { required, firstnameValidation, maxLength: maxLength(FIRST_NAME.MAX_LENGTH) },
      email: { required, email, maxLength: maxLength(EMAIL.MAX_LENGTH) },
      phone: { required },

    },
  },
  data: () => ({
    editedItem: {
      last_name: '',
      first_name: '',
      email: '',
      phone: '',
    },
    defaultItem: {
      last_name: '',
      first_name: '',
      email: '',
      phone: '',
    },
  }),
  watch: {
    /**
     * @param {boolean | null} newVal
     * @param {boolean | null} oldVal
     * */
    dialogCreateShow() {
      // По дефолту в positions есть одна запись по этому < 2
      // По возможности надо заморочиться и найти почему метод срабатывает по 2 раза с одинаковыми состояниями new и old
    //   if (newVal && newVal !== oldVal && this.positions?.length < 2 && !this.loadingPositions.loadPositions) {
    //     this.loadPositions();
    //   }
    },
  },
  computed: {
    ...mapGetters({
      loading: 'users/partners/loading',
      errors: 'users/partners/errors',
      editedCandidate: 'users/partners/editedCandidate',
    //   positions: 'positions/parnters/positions',
    //   loadingPositions: 'positions/parnters/loading',
    }),
    dialogCreateShow: {
      get() {
        return this.$store.getters['users/partners/dialogCreateShow'];
      },
      set(val) {
        if (!val) {
          this.editedItem = cloneDeep(this.defaultItem);
          this.$v.$reset();
        }
        this.setDialogCreateShow(val);
      },
    },
    dialogDeleteShow: {
      get() {
        return this.$store.getters['users/partners/dialogDeleteShow'];
      },
      set(val) {
        this.setDialogDeleteShow(val);
      },
    },
    lastnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.last_name.$dirty) return errors;
      if (!this.$v.editedItem.last_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.last_name.lastnameValidation) errors.push(MESSAGES.INCORRECT_LASTNAME);
      if (!this.$v.editedItem.last_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(LAST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'last_name')?.[1] ?? errors;
    },
    firstnameErrors() {
      const errors = [];
      if (!this.$v.editedItem.first_name.$dirty) return errors;
      if (!this.$v.editedItem.first_name.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.first_name.firstnameValidation) errors.push(MESSAGES.INCORRECT_FIRSTNAME);
      if (!this.$v.editedItem.first_name.maxLength) errors.push(MESSAGES.MAX_LENGTH(FIRST_NAME.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'first_name')?.[1] ?? errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.editedItem.email.$dirty) return errors;
      if (!this.$v.editedItem.email.required) errors.push(MESSAGES.REQUIRED);
      if (!this.$v.editedItem.email.email) errors.push(MESSAGES.INCORRECT_EMAIL);
      if (!this.$v.editedItem.email.maxLength) errors.push(MESSAGES.MAX_LENGTH(EMAIL.MAX_LENGTH));

      return this.errors.create.find((err) => err[0] === 'email')?.[1] ?? errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.editedItem.phone.$dirty) return errors;
      if (!this.$v.editedItem.phone.required) errors.push(MESSAGES.REQUIRED);
      return this.errors.create.find((err) => err[0] === 'phone')?.[1] ?? errors;
    },
  },
  methods: {
    ...mapActions({
      clearError: 'users/partners/clearError',
      setDialogCreateShow: 'users/partners/setDialogCreateShow',
      setDialogDeleteShow: 'users/partners/setDialogDeleteShow',
      createPartner: 'users/partners/createPartner',
      deleteCandidates: 'users/partners/deleteCandidates',
    //   loadPositions: 'positions/positions/loadPositions',
    }),
    deleteCandidateConfirm() {
      this.deleteCandidates([this.editedCandidate.id]).then(() => {
        this.dialogDeleteShow = false;
      });
    },
    onInput() {
      if (this.errors.create.length) this.clearError();
    },
    createPartnerConfirm() {
      if (this.loading.create) return;
      this.$v.$touch();
      if (!this.editedCandidate && !this.$v.$invalid) {
        const form = {
          ...this.editedItem,
        };
        this.createPartner(form).then(() => {
          this.dialogCreateShow = false;
        });
      }
    },
  },
};
